var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"date-item d-flex justify-start align-center",class:{
       'last': _vm.slotGroup.shift === _vm.ShiftType.THIRD,
        'calendar-date-today' : _vm.isToday(_vm.slotGroup.date),
        'calendar-date' : !_vm.isParticularDay([6,7], _vm.slotGroup.date),
        'calendar-date-saturday' : _vm.isParticularDay(6, _vm.slotGroup.date),
        'calendar-date-sunday' : _vm.isParticularDay(7, _vm.slotGroup.date),
        'calendar-date-special-day' : false,
     },on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click', _vm.slotGroup.date)}}},[(_vm.slotGroup.shift === _vm.ShiftType.FIRST)?_c('div',{staticClass:"rotate-90 text-center day-information"},[_c('h4',[_vm._v(_vm._s(_vm.$isoStringDateHumanized(_vm.slotGroup.date)))])]):(_vm.slotGroup.shift === _vm.ShiftType.SECOND)?_c('div',{staticClass:"rotate-90 text-center day-information"},[(!_vm.isInTimeRange)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
     var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v("fas fa-lock")])]}}],null,false,3071624901)},[_c('span',[_vm._v(_vm._s(_vm.$t('CALENDAR.OUT_OF_TIME_RANGE_ERROR')))])]):_vm._e()],1):_c('div',{staticClass:"rotate-90 text-center day-information last"},[_c('h4',[_vm._v(_vm._s(_vm.$isoStringWeekdayHumanized(_vm.slotGroup.date)))])]),_c('div',{staticClass:"d-flex justify-center align-center fill-height shift-indicator"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
     var on = ref.on;
return [_c('h4',_vm._g({staticClass:"rotate-90"},on),[_vm._v(_vm._s(_vm.shiftTypeAbbrv))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.shiftTooltip))])])],1),_c('div',{staticClass:"fill-height d-flex flex-column justify-center align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
     var on = ref.on;
return [_c('div',_vm._g({staticClass:"slot-type-indicator calendar-item d-flex justify-center align-center",attrs:{"id":_vm.elementCarId}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-car")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('GENERAL.CAR')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
     var on = ref.on;
return [_c('div',_vm._g({staticClass:"slot-type-indicator calendar-item last d-flex justify-center align-center",class:{'last-of-day': _vm.slotGroup.shift === _vm.ShiftType.THIRD},attrs:{"id":_vm.elementHeavyId}},on),[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center align-center mr-1"},[_c('v-icon',{staticClass:"mb-1",attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-truck")]),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-tractor")])],1),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-box")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('GENERAL.HEAVY')))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }