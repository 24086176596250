




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import SlotGroup from '@/models/SlotGroup.model';
import { ShiftType } from '@/enums/ShiftType.enum';

@Component({})
export default class CalendarItemDateComponent extends Vue {

    /**
     * if
     */
    @Prop()
    public id!: number;
    /**
     * Current slot group
     */
    @Prop()
    public slotGroup!: SlotGroup;
    /**
     * The min date when an interaction for non admin user is possible
     */
    @Prop()
    public minDate!: DateTime;

    /**
     * The max date when an interaction for non admin user is possible
     */
    @Prop()
    public maxDate!: DateTime;

    private readonly MAX_TITLE_LENGTH: number = 25;
    private ShiftType = ShiftType;

    /**
     * Check if this date is in time range
     */
    public get isInTimeRange(): boolean {
        const currentDate = DateTime.fromISO(this.slotGroup.date, { zone: 'utc' });
        return this.minDate <= currentDate && this.maxDate >= currentDate;
    }

    public get elementCarId(): string {
        return `${this.slotGroup.date!}-${2 * this.id}`;
    }

    public get elementHeavyId(): string {
        return `${this.slotGroup.date!}-${2 * this.id + 1}`;
    }

    /**
     * Returns an abbrv for the current shift type
     */
    public get shiftTypeAbbrv(): string {
        switch (this.slotGroup.shift) {
            case ShiftType.FIRST:
                return 'I';
            case ShiftType.SECOND:
                return 'II';
            case ShiftType.THIRD:
                return 'III';
            default:
                return '?';
        }
    }

  /**
   * Returns an tooltip for the current shift type
   */
  public get shiftTooltip(): string {
    if (DateTime.fromISO(this.slotGroup.date).weekday === 6) {
      return this.$t(`SPECIAL_SHIFT_TYPES.TOOLTIPS.SATURDAY.${this.slotGroup.shift}`).toString();
    } else if (DateTime.fromISO(this.slotGroup.date).weekday === 7) {
      return this.$t(`SPECIAL_SHIFT_TYPES.TOOLTIPS.SUNDAY.${this.slotGroup.shift}`).toString();
    }
    return this.$t(`SPECIAL_SHIFT_TYPES.TOOLTIPS.${this.slotGroup.shift}`).toString();
  }

    /**
     * Support function to determine if the given date is today!
     */
    public isToday(date: string): boolean {
        const compareDay = DateTime.fromISO(date).startOf('day');
        const today = DateTime.local().startOf('day');
        return today.diff(compareDay, 'days').toObject().days === 0;
    }

    /**
     * Checks if the given date matches with the given day.
     * E. g. 1 means monday.
     * @param date
     */
    public isParticularDay(day: number | number[], date: string): boolean {
        if (!Array.isArray(day)) {
            day = [day];
        }
        return day.includes(DateTime.fromISO(date).weekday);
    }

    public sanitize(value: string): string {
        if (value.length > this.MAX_TITLE_LENGTH) {
            return `${value.slice(0, this.MAX_TITLE_LENGTH)}...`;
        }
        return value;
    }
}
